// blog
export const GET_TRIPS = 'GET_TRIPS';
export const GET_SINGLE_TRIP = 'GET_SINGLE_TRIP';
export const GET_SINGLE_GALLERY = 'GET_SINGLE_GALLERY';
export const GET_FRONT_POSTS = 'GET_FRONT_POSTS';
export const GET_FRONT_ATTACHMENT = 'GET_FRONT_ATTACHMENT';
export const GET_ATTACHMENT = 'GET_ATTACHMENT';
export const CLEAR_SINGLE_TRIP = 'CLEAR_SINGLE_TRIP';
export const CLEAR_TRIPS = 'CLEAR_TRIPS';
export const CLEAR_FRONT_TRIPS = 'CLEAR_FRONT_TRIPS';
export const SET_LOADING = 'SET_LOADING';
export const TRIP_ERROR = 'TRIP_ERROR';
export const SEARCH_TRIP = 'SEARCH_TRIP';
export const SET_PAGES = 'SET_PAGES';
export const SET_TRIPS_NUMBER = 'SET_TRIPS_NUMBER';
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
export const SET_SIDEBAR_TRIPS = 'SET_SIDEBAR_TRIPS';
export const SET_VIEW = 'SET_VIEW';
export const SET_IMAGES_NUMBER = 'SET_IMAGES_NUMBER';
export const SET_DATE = 'SET_DATE';
export const SET_DATE_SINGLE = 'SET_DATE_SINGLE';
export const SET_SIDEBAR_SINGLE = 'SET_SIDEBAR_SINGLE';
// map
export const GET_MAP_TRIPS = 'GET_MAP_TRIPS';
